<template>
  <div :class="{ 'border-bottom mb-2': showName }">
    <div v-if="showName" class="ui-type-heading text-primary">
      {{ variant.translations[lang].name }}
    </div>
    <CRow class="align-items-end">
      <CCol sm="auto" lg="4" xl="3">
        <CInput
          v-model="variant.price_len"
          :isValid="variant.isValid('price_len')"
          :invalidFeedback="variant.getErrorMessage('price_len')"
        >
          <template #label>
            <strong>Peso a pezzo</strong>
            <em class="text-danger">*</em>
          </template>
        </CInput>
      </CCol>
      <CCol sm="auto" lg="4" xl="3">
        <CSelect
          :value.sync="variant.price_unit"
          :options="priceUnitOptions"
          :isValid="variant.isValid('price_unit')"
          :invalidFeedback="variant.getErrorMessage('price_unit')"
          custom
        >
          <template #label>
            <strong>Unità di misura</strong>
            <em class="text-danger">*</em>
          </template>
        </CSelect>
      </CCol>
      <CCol sm="auto">
        <div class="form-group">
          <CInputCheckbox
            label="Circa"
            :checked.sync="variant.price_len_mol"
            :isValid="variant.isValid('price_len_mol')"
            :invalidFeedback="variant.getErrorMessage('price_len_mol')"
            custom
          />
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "ProductFormVariantWeight",
  props: {
    variant: { type: Object, required: true },
    lang: { type: String, required: true },
    priceUnitOptions: { type: Array, required: true },
    showName: { type: Boolean, default: false },
  },
};
</script>
