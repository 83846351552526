// form
import ProductFormInfo from "./ProductFormInfo.vue";
import ProductFormNav from "./ProductFormNav.vue";
import ProductFormPreviewModal from "./ProductFormPreviewModal.vue";
import ProductFormUpload from "./ProductFormUpload.vue";
import ProductFormVariantAvailability from "./ProductFormVariantAvailability.vue";
import ProductFormVariantMinQuantity from "./ProductFormVariantMinQuantity.vue";
import ProductFormVariantOrigin from "./ProductFormVariantOrigin.vue";
import ProductFormVariantPerishability from "./ProductFormVariantPerishability.vue";
import ProductFormVariantPrice from "./ProductFormVariantPrice.vue";
import ProductFormVariants from "./ProductFormVariants";
import ProductFormVariantSellTime from "./ProductFormVariantSellTime";
import ProductFormVariantWeight from "./ProductFormVariantWeight.vue";
// table
import ProductTableVariantActions from "./ProductTableVariantActions.vue";
import ProductTableVariantCategory from "./ProductTableVariantCategory.vue";
import ProductTableVariantImage from "./ProductTableVariantImage.vue";
import ProductTableVariantMarkets from "./ProductTableVariantMarkets.vue";
import ProductTableVariantPrice from "./ProductTableVariantPrice.vue";
import ProductTableVariantTitle from "./ProductTableVariantTitle.vue";

export {
  ProductFormInfo,
  ProductFormNav,
  ProductFormPreviewModal,
  ProductFormUpload,
  ProductFormVariantAvailability,
  ProductFormVariantMinQuantity,
  ProductFormVariantOrigin,
  ProductFormVariantPerishability,
  ProductFormVariantPrice,
  ProductFormVariants,
  ProductFormVariantSellTime,
  ProductFormVariantWeight,
  ProductTableVariantActions,
  ProductTableVariantCategory,
  ProductTableVariantImage,
  ProductTableVariantMarkets,
  ProductTableVariantPrice,
  ProductTableVariantTitle,
};
