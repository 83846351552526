<template>
  <div>
    <router-link :to="productEditRoute">
      <strong>{{ getProductName() }}</strong>
    </router-link>
    <router-link :to="productEditRoute">
      <div>{{ getVariantName() }}</div>
    </router-link>
  </div>
</template>

<script>
import get from "lodash/get";
export default {
  name: "ProductTableVariantTitle",
  data() {
    return {
      data: {},
    };
  },
  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
    productEditRoute() {
      if (this.$store.state.role && this.$store.state.role.id) {
        return {
          name: "RoleProductsEdit",
          params: {
            id: this.$store.state.role.id,
            product_id: this.data.productproducer.id,
          },
        };
      }
      return {};
    },
  },
  methods: {
    getProductName() {
      return get(this.data, `productproducer.translations.${this.lang}.name`);
    },
    getVariantName() {
      return get(this.data, `translations.${this.lang}.name`);
    },
  },
};
</script>
